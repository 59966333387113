<template>
  <div v-if="!token" class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signup-on"
      id="kt_login"
    >
      <div class="side_image">
        <img src="/media/side_image.png" />
      </div>
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <!--begin::Signup-->
        <div class="login-form login-signup">
          <div class="logo text-center mb_85 pt-lg-0">
             <img
                  v-if="base_URL.includes('fehm')"
                  style="width: 86%"
                  src="/media/fehm_logo.png"
                />
                       <img v-else-if="base_URL.includes('mcit')"  alt="Logo" src="/media/logo.png" />
                <img v-else  alt="Logo" src="/media/Logos/manash.png" />
          </div>
          <div v-if="error_mesage.length" class="error_mesage">
            <h3>برجاء ادخال البيانات التاليه :</h3>
            <ul>
              <li v-for="err in error_mesage" :key="err">
                {{ err }}
              </li>
            </ul>
          </div>
          <div v-if="API_ERROR == 'pass_same'" class="error_mesage">
            <p>كلمة المرور وتأكيد كلمة المرور غير متشابهان</p>
          </div>
          <div v-if="API_ERROR == 'email_taken'" class="error_mesage">
            <p>هذا البريد موجود بالفعل بالرجاء إختيار بريد إلكترونى اخر</p>
          </div>
          <div v-if="API_ERROR == 'mobile_taken'" class="error_mesage">
            <p>هذا الرقم موجود بالفعل بالرجاء إختيار رقم هاتف اخر</p>
          </div>
          <div v-if="API_ERROR == 'mobile_notvalid'" class="error_mesage">
            <p>رقم الهاتف غير صالح</p>
          </div>
          <div v-if="API_ERROR == 'email_notvalid'" class="error_mesage">
            <p>بريد إلكترونى غير صالح</p>
          </div>
          <div v-if="sucss_mesage == 'mobile_updated'" class="sucsess_mesage">
            <p>تم تعديل رقم الهاتف بنجاح وإرسال رمز التأكيد الى رقمك الجديد</p>
          </div>
          <div v-if="sucss_mesage == 'email_updated'" class="sucsess_mesage">
            <p>
              تم تعديل البريد الإلكترونى بنجاح وإرسال رسالة التأكيد الى بريدك
              الجديد
            </p>
          </div>

          <form
            v-if="mail_activated == true"
            class="form"
            novalidate="novalidate"
            id="kt_login_signin_form"
          >
            <p class="message_info_Green">
              تم تفعيل حسابك... برجاء تكملة باقي البيانات التالية
            </p>
            <div class="form-group d-block align-right justify-content-center">
              <label>إسم الشركة</label>
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="form.company"
                  type="text"
                  placeholder="إسم الشركة"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group d-block align-right justify-content-center">
              <label>المسمي الوظيفي</label>
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="form.job_title"
                  type="text"
                  placeholder="المسمي الوظيفي"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="form-group d-block align-right justify-content-center">
              <label>صورة شخصية</label>
              <div v-if="!previewImage" class="photo_register text-center">
                <div class="imagePreviewWrapper">
                  <label
                    title="رفع صورة المستخدم"
                    class="image_uplode_input_label"
                  >
                    <div>
                      <i class="fas fa-image"></i>
                      <h5>رفع صورة شخصية</h5>
                      <p>Support: JPG, JPEG2000 and PNG</p>
                    </div>
                    <input
                      class="image_uplode_input"
                      ref="fileInput"
                      type="file"
                      @input="pickFile"
                      size="60"
                    />
                  </label>
                </div>
              </div>
              <div v-else class="photo_register text-center">
                <div class="imagePreview">
                  <img :src="previewImage" />

                  <div class="image_data d-block">
                    <h5>{{ photo_name }}</h5>
                    <div class="action_btn">
                      <button type="button" class="btn btn_edit text-center">
                        <label title="رفع صورة المستخدم" class="">
                          <div>
                            <img src="/media/svg/edit_metings.svg" />
                          </div>
                          <input
                            class="image_uplode_input"
                            ref="fileInput"
                            type="file"
                            @input="pickFile"
                            size="60"
                          />
                        </label>
                      </button>
                      <button
                        type="button"
                        @click="previewImage = null"
                        class="btn text-center btn_remove"
                      >
                        <img src="/media/svg/delete.svg" />
                      </button>
                    </div>
                  </div>
                  <!--  -->
                </div>
              </div>
            </div>

            <div class="form-group">
              <button
                style="width: 100%"
                ref="kt_login_signin_submit"
                class="btn btn_brimary"
                type="button"
                @click="onSubmit()"
              >
                تسجيل
              </button>
              <div class="form-group">
                <span class="text-muted font-weight-bold font-size-h4">
                  لديك حساب بالفعل ؟
                  <a
                    id="kt_login_signup"
                    class="cursor_pointer text-primary font-weight-bolder"
                    @click="$router.push('/login')"
                  >
                    قم بتسجيل الدخول
                  </a></span
                >
              </div>
            </div>
          </form>
          <div class="not_activated" v-if="mail_activated == false">
            لم يتم تفعيل حسابك برجاء التأكد من الرابط المرسل إليك وإعاده
            المحاولة
          </div>
        </div>
        <!--end::Signup-->

        <!--begin::Content footer-->
        <div
          class="login_footer d-flex text-center justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          جميع الحقوق محفوظه لدى وزارة الاتصالات وتقنية المعلومات 2021
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column bgi-no-repeat bgi-size-cover bgi-position-y-bottom bgi-position-x-center flex-row-auto"
        :style="{ backgroundImage: `url(/media/Path1.svg` }"
      >
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat position-relative bgi-size-cover bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(/media/tower.png)` }"
        >
          <div class="slogin_container">
            <h3>
              تمكين المملكة العربية السعودية , اقتصاديا<br />
              ومجتمعا ووطنا, من استثمار الفرص التى يوفرها<br />
              عنصر الرقمنه والوصول بالمملكة لطليعة الدول<br />
              المبتكرة
            </h3>
          </div>
          <div class="green_path">
            <img src="/media/Path2.svg" />
          </div>
        </div>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
// import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
// import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
// import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import auth from "../../../../http/requests/auth";
export default {
  name: "login-1",
  data() {
    return {
      previewImage: null,
      isLoading: false,
      enable_btn: false,
      fileRecords: [],
      edit_num: false,
      edit_email: false,
      Seconds: null,

      state: "signin",
      form: {},
      country_code: "",
      mobile: "",
      API_ERROR: "",
      error_mesage: [],
      sucss_mesage: "",
      verfication_code: null,
      new_mobile: "",
      new_email: "",
      photo_name: "",
      user_photo: "",
      mail_activated: null,
      token: null,
      base_URL:''
    };
  },
  methods: {
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.user_photo = input.files[0];
      this.photo_name = input.files[0].name;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    handlePhoneCountry(info) {
      this.country_code = info.dialCode;
    },
    onSubmit() {
      this.error_mesage = [];
      this.API_ERROR = "";
      if (!this.form.company) {
        this.error_mesage.push("إسم الشركة");
      }
      if (!this.form.job_title) {
        this.error_mesage.push("المسمى الوظيفى");
      }
      if (this.form.job_title && this.form.company) {
        let formData = new FormData();
        formData.append("company", this.form.company);
        formData.append("job_title", this.form.job_title);
        formData.append("_method", "PUT");
        formData.append("email", this.$route.params.email);
        if (this.user_photo) {
          formData.append("avatar", this.user_photo);
        }
        this.$vs.loading();
        auth
          .update_user_info(formData)
          .then((res) => {
            this.form = {};
            window.localStorage.setItem(
              "User_data",
              JSON.stringify(res.data.data)
            );
            window.localStorage.setItem("id_token", res.data.token);
            window.location.href = "/";
          })
          .catch((err) => {
            this.$vs.loading.close();
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          });
      }
    },
    //    "email":"anwarsaeedd11@gmail.com",
    //     "":"dd9e61d2137c97142435610999df72fb"
    Validate_email() {
      this.$vs.loading();
      const form = {
        email: this.$route.params.email,
        activation_token: this.$route.params.activation_token,
      };
      auth
        .verfiy_email(form)
        .then((res) => {
          this.mail_activated = true;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.mail_activated = false;
          this.$vs.loading.close();
        });
    },
  },
  created() {
      this.base_URL = window.location.origin;
    this.token = localStorage.getItem("id_token");
    if (this.token) {
      window.location.href = "/";
    } else {
      this.Validate_email();
    }
  },
};
</script>
<style scoped lang="scss">
//  #1bb04e: #1bb04e;
.not_activated {
  color: red;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
.photo_register {
  margin-bottom: 30px;
  width: 100%;
  border: 1px dashed gainsboro;
  .imagePreviewWrapper {
    width: 150;
    background-size: cover;
    margin: auto;
    position: relative;
  }
  .imagePreview {
    display: flex;
    .image_data {
      margin: 20px;
      width: 280px;
      margin-right: 10px;
      overflow: hidden;
      text-align: right;
      h5 {
        color: #1eb04e;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    img {
      width: 100px;
      height: 100px;
      margin: 20px;
      border-radius: 4px;
      margin-left: 0;
    }
  }

  .image_uplode_input {
    display: none;
  }
  .image_uplode_input_label {
    width: 100%;
    height: 100%;
    // left: 0;
    // bottom: 0;
    cursor: pointer;
    // margin-bottom: 0 !important;
    // position: absolute !important;
    padding: 10px;
    background: transparent;
    display: table;
    color: #fff;
    .fas {
      color: #1bb04e;
      font-size: 16px;
      background: #d4edda;
      padding: 12px;
      border-radius: 4px;
    }
    h5 {
      color: #1eb04e;
      font-weight: bold;
      margin-top: 10px;
    }
    p {
      color: #9f9f9f;
      font-size: 9px;
      margin-top: 5px;
    }
  }
}
.action_btn {
  display: flex;

  button {
    height: 30px;
    width: 30px;
    padding: 6px !important;

    img {
      margin: 0 !important;
      height: auto !important;
      width: 17px !important;
    }
  }
  .btn_edit {
    background-color: #dcecf9;
    margin-left: 5px;
  }
  .btn_remove {
    background-color: #ffe1e2;
  }
}
.btn_brimary {
  margin-bottom: 20px;
}
.btn_Green {
  padding: 8px 20px;
  margin-top: 10px;
}
.form-control {
  background: transparent !important;
}
</style>